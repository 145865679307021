<template>
    <section class="content">
        <div class="card" v-if="false">
            <div class="card-header">
                Filter Pencarian
            </div>
                <!-- <div class="p-5"></div> -->
            <div class="card-body row">
                <div class="col-md-6 form-group">
                    <label class="control-label">Cabang</label>
                    <v-select :options="optCabang" v-model="filter.branch_id" :reduce="opt => opt.branch_id" />
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-hover" ref="tableuser">
                <thead>
                    <tr>
                        <th>TANGGAL PENGAJUAN</th>
                        <th>CABANG</th>
                        <th>NAMA LENGKAP</th>
                        <th>ASAL SEKOLAH</th>
                        <th>KELAS</th>
                        <th>NO. HP</th>
                        <th>TANGGAL KONSULTASI</th>
                        <th>LOKASI KONSULTASI</th>
                        <th>KEHADIRAN ORTU</th>
                        <th>PETUGAS PR</th>
                        <th>STATUS</th>
                        <th>HASIL KONSULTASI</th>
                        <th>TINDAKAN</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
            </table>
        </div>
        <input type="text" class="form-control" ref="daterange" id="daterange" autocomplete="off" style="display:none">
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Tanggal Pengajuan</label>
                                <input type="hidden" name="row_id" v-model="form.id"/>
                                <input type="hidden" name="created_by" v-model="form.created_by"/>
                                <datepicker
                                  v-model="form.tgl_pengajuan"
                                />
                            </div>
                            <div class="col-md-6 form-group" v-if="false">
                            <label class="control-label">Cabang</label>
                            <select
                                v-model="form.branch_id"
                                id="branch_id"
                                name="branch_id"
                                class="form-control">
                                <option
                                v-for="cabang in groups"
                                v-bind:key="cabang.branch_id"
                                v-bind:value="cabang.branch_id"
                                >
                                {{ cabang.label }}
                                </option>
                            </select>
                            </div>
                            <!-- <div class="col-md-12 form-group">
                            <label class="control-label">SISWA</label>
                            <v-select
                                name="siswa_id"
                                :filterable="false"
                                :options="nomorSiswa"
                                v-model="form.siswa_id"
                                :reduce="(opt) => opt.id"
                                @search="onSearch"
                                placeholder="Ketik nama siswa / no. pendaftaran..">
                                <template slot="no-options"> Tidak ada... </template>
                                <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.label }}
                                </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.label }}
                                </div>
                                </template>
                            </v-select>
                            </div> -->
                            <div class="col-md-12 form-group">
                            <label class="control-label">SISWA</label>
                            <v-select :options="optAudience" v-model="form.rekap_audience_id" :reduce="opt => opt.id" class="siswa"/>
                            <!-- <v-select
                                taggable
                                :options="optAudience"
                                :create-option="book => ({ nama: book,})"
                                :reduce="book => `${book.nama}`"
                                label="nama"
                                v-model="form.rekap_audience_id"
                                @input="setSiswa"
                            /> -->
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Asal Sekolah</label>
                                <input id="asal_sekolah" class="form-control asal_sekolah" v-model="form.asal_sekolah" type="text" name="asal_sekolah" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Kelas</label>
                                <input id="kelas" class="form-control kelas" v-model="form.kelas" type="text" name="kelas" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">No. HP</label>
                                <input id="no_hp" class="form-control no_hp" v-model="form.no_hp" type="number" name="no_hp" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Judul</label>
                                <input id="title" class="form-control title" v-model="form.title" type="text" required="required" name="title" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Keterangan</label>
                                <textarea class="form-control" v-model="form.description"></textarea>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Tanggal Konsultasi</label>
                                <datepicker
                                  v-model="form.tgl_konsultasi"
                                />
                            </div>
                            <div class="col-md-6 form-group">
                            <label class="control-label">WAKTU</label>
                            <div class="row">
                                <timepicker
                                class="col-md-5 ml-2"
                                placeholder="Jam mulai"
                                v-model="form.jam_mulai"
                                />
                                <timepicker
                                class="col-md-5"
                                placeholder="Jam selesai"
                                v-model="form.jam_selesai"
                                />
                            </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Lokasi Konsultasi</label>
                                <input id="lokasi" class="form-control" v-model="form.lokasi" type="text" name="lokasi" />
                            </div>
                            <label for="is_hadir">
                                Kehadiran Ortu</label>
                            <div class="col-md-12 form-group">
                                <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="is_hadir"
                                    id="is_hadir"
                                    v-model="form.is_hadir"
                                    value="true"
                                />
                                <label class="form-check-label" for="is_hadir"
                                    >YA</label
                                >
                                </div>
                                <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="is_hadir"
                                    id="is_hadir"
                                    v-model="form.is_hadir"
                                    value="false"
                                />
                                <label class="form-check-label" for="is_hadir"
                                    >TIDAK</label
                                >
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Petugas PR</label>
                                <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Hasil</label>
                                <textarea class="form-control" v-model="form.hasil"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="submit" class="btn btn-primary" id="btnsubmit">
                                Save changes
                            </button>
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { auth, createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import Swal from "sweetalert2";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import moment from 'moment';
import datepicker from "@/components/Datepicker";
import timepicker from "@/components/Timepicker";

export default {
    name: 'PengajuanKonsultasi',
    components: {
        vSelect,
        datepicker,
        timepicker
    },
    data() {
        return {
            errors: [],
            // options: [
            //     {
            //         title: "HTML5",
            //         author: {
            //             firstName: "Remy",
            //             lastName: "Sharp"
            //         }
            //     }
            // ],
            optCabang: [],
            groups: [],
            nomorSiswa: [],
            nomorSiswaBase: [],
            optStaff: [],
            optAudience: [],
            method: '',
            roles: '',
            userInfo: '',
            submit: '',
            formTitle: 'Tambah',
            filter: {
                branch_id: '',
            },
            form: {
                tgl_pengajuan: moment().format('DD/MM/YYYY'),
                tgl_konsultasi: moment().format('DD/MM/YYYY'),
                title: '',
                branch_id: '',
                description: '',
                siswa_id: '',
                staff_id: '',
                lokasi: '',
                is_hadir: '',
                catatan: '',
                created_by: '',
                hari: '',
                hasil: '',
                jam_mulai: '',
                jam_selesai: '',
                rekap_audience_id: '',
                nama: '',
                asal_sekolah: '',
                no_hp: '',
                kelas: '',
            },
            cabang: '',
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        if (auth.user()) {
            this.userInfo = auth.user();
            this.form.created_by = auth.user().id;
        }

        authFetch('/pr/pengajuan_konsultasi/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optCabang = js.data;
                this.groups = this.optCabang;
        });

        authFetch('/pr/pengajuan_konsultasi/audience')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optAudience = js.data;
                // let len = js.data.length;
                // let val = js.data;
                // for (let i = 0; i <= len; i++) {
                //     // const element = array[i];
                //     this.optAudience.push({
                //         id: val[i].id,
                //         nama: val[i].nama,
                //     });
                // }
        });

        this.loadSiswa();
    },
    methods: {
        search: function() {
            let self = this;
            self.cabang = self.filter.branch_id;
            this.table.api().ajax.reload();
        },
        loadSiswa(){
            authFetch("/pr/pengajuan_konsultasi/siswa").then((res) => {
                res.json().then((json) => {
                    //get default address
                    if (json.success) {
                        this.nomorSiswa = json.data
                        this.nomorSiswaBase = json.data
                    }
                });
            });
        },
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }

            if (e.target.matches("button")) {
                if (e.target.dataset.action == "confirm") {
                this.submit = "approve";
                Swal.fire({
                    title: "Konfirmasi?",
                    showCancelButton: true,
                    confirmButtonText: `Ya`,
                    cancelButtonText: "Tidak",
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                    authFetch("/pr/pengajuan_konsultasi/approval/" + e.target.dataset.id, {
                        method: "PUT",
                        body: "submit=" + this.submit,
                    })
                        .then((res) => {
                        return res.json();
                        })
                        .then((js) => {
                        if (js.success) {
                            Swal.fire("Proses Berhasil", ``, "success");
                            this.table.api().ajax.reload();
                        } else {
                            Swal.fire("Proses gagal", ``, "error");
                            this.table.api().ajax.reload();
                        }
                        });
                    }
                });
                } else if (e.target.dataset.action == "reject") {
                this.submit = "reject";
                Swal.fire({
                    title: "Tolak?",
                    showCancelButton: true,
                    confirmButtonText: `Ya`,
                    cancelButtonText: "Tidak",
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                    authFetch("/pr/pengajuan_konsultasi/approval/" + e.target.dataset.id, {
                        method: "PUT",
                        body: "submit=" + this.submit,
                    })
                        .then((res) => {
                        return res.json();
                        })
                        .then((js) => {
                        if (js.success) {
                            Swal.fire("Proses Berhasil", ``, "success");
                            this.table.api().ajax.reload();
                        } else {
                            Swal.fire("Proses gagal", ``, "error");
                            this.table.api().ajax.reload();
                        }
                        });
                    }
                });
                }
                console.log(e.target);
                return false;
            }
        },
        onSearch(search, loading) {
            loading(true);
            var vm = this;
            authFetch(
            `/pr/pengajuan_konsultasi/cari_siswa?q=${escape(search)}`
            ).then((res) => {
            res.json().then((json) => (json.items.length > 0 ? vm.nomorSiswa = json.items : vm.nomorSiswa = this.nomorSiswaBase));
            loading(false);
            });
        },
        onChange: function() {
            var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
            namahari = namahari.split(" ");
            if (this.form.tanggal_mulai) {
                var split = this.form.tanggal_mulai.split('/')
                var tgl = new Date(split[2],split[1]-1,split[0]);
                var hari = tgl.getDay();

                this.form.hari = namahari[hari];
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/pr/pengajuan_konsultasi';
            if (this.method == 'PUT') urlSubmit = '/pr/pengajuan_konsultasi/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;

        authFetch('/pr/pengajuan_konsultasi/staff')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optStaff = js.data;
        });

        var isAdmin = this.userInfo.role_id < 3;
        this.table = createTable(e.tableuser, {
            title: "List Pengajuan Konsultasi",
            roles: this.$route.params.roles,
            ajax: "/pr/pengajuan_konsultasi",
            serverSide: true,
            scrollX: true,
            columns: [
                { data: "tgl_pengajuan" },
                { data: "cabang" },
                { data: "nama_siswa" },
                { data: "asal_sekolah" },
                { data: "kelas" },
                { data: "no_hp" },
                { data: "tgl_konsultasi" },
                { data: "lokasi" },
                { data: "is_hadir",
                 render: function (data, type, row, meta) {
                    return data == "t" ? 'YA' : 'TIDAK';
                 },},
                { data: "staff" },
                { data: "status",
                 visible: isAdmin ? false : true,
                 render: function (data, type, row, meta) {
                    return data < 1 ? 'MENUNGGU' : 'SELESAI' 
                  },},
                { data: "note_hasil",
                render: function (data, type, row, meta) {
                    return '<span class="badge badge-primary">' + data +'</span>';
                } },
                { data: "action",
                visible: false,//isAdmin ? true : false,
                sortable: false,
                render: function (data, type, row, meta) {
                    if (!row.is_approved) {
                        return `<div class="btn-group">
                    <button type="button" class="btn btn-sm btn-outline-info" data-action="confirm" data-id="` +
                        row.id +
                        `"><i class="fas fa-check"></i> Approve</button>
                                <button type="button" class="btn btn-sm btn-outline-danger" data-action="reject" data-id="` +
                        row.id +
                        `"><i class="fas fa-times"></i> Reject</button>
                            </div>`;
                    } else {
                        return row.is_approved == "f" ? `<span class="badge badge-danger">REJECT</span>` : `<span class="badge badge-success">APPROVED</span>`;
                    }
                }, },
            ],
            filterBy: [1, 2, 3, 6],
            paramData: function ( d ) {
                // d.cabang_id = self.cabang;
            },
            rowCallback: function(row, data) {
                // var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                // namahari = namahari.split(" ");
                // var tgl = new Date(data.tgl_day);
                // var hari = tgl.getDay();
                // $('td:eq(0)', row).html(namahari[hari]);
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    $('#btnsubmit').show();
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Pengajuan Konsultasi'; 
                    if (auth.user()) {
                        self.form.created_by = auth.user().id;
                    }
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    authFetch('/pr/pengajuan_konsultasi/audience')
                        .then(res => {
                            if (res.status === 201) {

                            } else if (res.status === 400) {}
                            return res.json();
                        })
                        .then(js => {
                            this.optAudience = js.data;
                            // let len = js.data.length;
                            // let val = js.data;
                            // for (let i = 0; i <= len; i++) {
                            //     // const element = array[i];
                            //     this.optAudience.push({
                            //         id: val[i].id,
                            //         nama: val[i].nama,
                            //     });
                            // }
                    });
                    self.form.tgl_pengajuan = evt.data.tgl_pengajuan;
                    self.form.tgl_konsultasi = evt.data.tgl_konsultasi;
                    self.form.id = evt.data.id;
                    self.form.title = evt.data.title;
                    self.form.description = evt.data.description;
                    self.form.staff_id = evt.data.staff_id;
                    self.form.branch_id = evt.data.branch_id;
                    self.form.jam_mulai = evt.data.jam_mulai;
                    self.form.jam_selesai = evt.data.jam_selesai;
                    self.form.asal_sekolah = evt.data.asal_sekolah;
                    self.form.rekap_audience_id = evt.data.rekap_audience_id;
                    self.form.nama = evt.nama_siswa
                    self.form.no_hp = evt.data.no_hp;
                    self.form.kelas = evt.data.kelas;
                    
                    self.optStaff.push({
                        id: self.form.staff_id,
                        label: evt.data.staff
                    });
                    
                    // self.nomorSiswa.push({
                    //     id: evt.data.rekap_audience_id,
                    //     label: evt.data.nama_siswa,
                    // });

                    self.form.lokasi = evt.data.lokasi;
                    self.form.hasil = evt.data.note_hasil;
                    self.form.is_hadir = evt.data.is_hadir == "t" ? "true" : "false";
                    // if(evt.data.status == 1) $('#btnsubmit').hide();
                    // if(evt.data.is_approved == "t" || evt.data.is_approved == "f") $('#btnsubmit').hide();
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Pengajuan Konsultasi';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    Swal.fire({
                        title: "Hapus data?",
                        icon: "question",
                        denyButtonText: '<i class="fa fa-times"></i> Hapus',
                        showCancelButton: true,
                        showDenyButton: true,
                        showConfirmButton: false,
                    }).then((result) => {
                        if (result.isDenied) {
                        authFetch("/pr/pengajuan_konsultasi/" + evt.data.id, {
                            method: "DELETE",
                            body: "id=" + evt.data.id,
                        })
                            .then((res) => {
                            return res.json();
                            })
                            .then((js) => {
                            this.table.api().ajax.reload();
                            });
                        }
                    });
                }

            },
        });
        new Daterangepicker(this.$refs.daterange, {
        }, function(d1, d2) {
            self.form.start = d1.format('DD/MM/YYYY')
            self.form.end = d2.format('DD/MM/YYYY')
        });

    }
}
</script>